var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card-title',[_c('h1',[_vm._v("Production Planning")]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-10",attrs:{"elevation":"2","color":"#33cc99","dark":""},on:{"click":function($event){return _vm.$router.push('/new-production-plan')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" New Production Plan ")],1),_c('v-btn',{staticClass:"ml-2",attrs:{"elevation":"2","dark":""},on:{"click":function($event){return _vm.$router.push('/production-plan-scanner')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-qrcode-scan")]),_vm._v(" Scanner ")],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"4"}},[_c('v-data-table',{attrs:{"headers":_vm.productionPlanHeaders,"items":_vm.filteredPlans,"items-per-page":500,"dense":"","show-expand":"","single-expand":"","item-key":"plan_id","group-by":"plan_date"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","dense":"","outlined":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.statusOptions,"label":"Status","clearable":"","item-text":"text","item-value":"value","dense":"","outlined":""},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1)],1)],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('v-sheet',{staticClass:"py-3",attrs:{"elevation":"0"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"6"}},[_c('h4',[_vm._v("Item name")])]),_c('v-col',{attrs:{"md":"3","sm":"3"}},[_c('h4',[_vm._v("Total quantity")])]),_c('v-col',{attrs:{"md":"3","sm":"3"}},[_c('h4',[_vm._v("Related orders")])])],1),_vm._l((item.plan_items),function(e,index){return _c('div',{key:e.index},[_c('v-row',{staticClass:"py-2"},[_c('v-col',{attrs:{"md":"6","sm":"6"}},[_vm._v(" "+_vm._s(e.combined_name)+" ")]),_c('v-col',{attrs:{"md":"3","sm":"3"}},[_vm._v(" "+_vm._s(e.total_qty)+" ")]),_c('v-col',{attrs:{"md":"3","sm":"3"}},[_vm._v(" "+_vm._s(e.orders)+" ")])],1),(index < item.plan_items.length -1)?_c('v-divider',{key:index}):_vm._e()],1)})],2)],1)],1)]}},{key:"item.plan_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.plan_name ? item.plan_name : "")+" ")]}},{key:"item.plan_status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.plan_status),"small":""}},[_vm._v(" "+_vm._s(item.plan_status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.prepareJobTraveller(item)}}},[_c('v-list-item-title',[_vm._v("Print Job Traveller "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-printer")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.printLabels(item)}}},[_c('v-list-item-title',[_vm._v("Print Labels "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-label")])],1)],1),(item.plan_status === 'Created')?_c('v-list-item',{on:{"click":function($event){return _vm.updatePlanStatus(item, 'Completed')}}},[_c('v-list-item-title',[_vm._v("Mark as \"Complete\" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-check")])],1)],1):_vm._e(),(item.plan_status === 'Created')?_c('v-list-item',{on:{"click":function($event){return _vm.updatePlanStatus(item, 'Cancelled')}}},[_c('v-list-item-title',[_vm._v("Mark as \"Cancelled\" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-close")])],1)],1):_vm._e()],1)],1)]}},{key:"group.header",fn:function(ref){
var items = ref.items;
return [_c('th',{staticClass:"indigo lighten-5",staticStyle:{"height":"36px"},attrs:{"colspan":_vm.productionPlanHeaders.length}},[_vm._v(" "+_vm._s(new Date(items[0].plan_date).toLocaleDateString("en-GB", { year: "numeric", month: "long", day: "numeric", }))+" ")])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.travellerDialog),callback:function ($$v) {_vm.travellerDialog=$$v},expression:"travellerDialog"}},[(_vm.currentPlan)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.currentPlan.plan_number))]),_c('v-data-table',{attrs:{"headers":_vm.pageOptionsHeaders,"items":_vm.pageOptions,"show-select":"","item-key":"label","item":"","selectable-key":"lable","items-per-page":500,"hide-default-footer":""},model:{value:(_vm.selectedPageOptions),callback:function ($$v) {_vm.selectedPageOptions=$$v},expression:"selectedPageOptions"}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.travellerDialog = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.generateJobTraveller}},[_vm._v("Print Traveller")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }