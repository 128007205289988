<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card-title>
                    <h1>Production Planning</h1>
                    <!--Button-->
                    <v-spacer />
                    <v-btn
                        elevation="2"
                        color="#33cc99"
                        dark
                        class="ml-10" 
                        @click="$router.push('/new-production-plan')"
                        >
                        <v-icon left>mdi-plus</v-icon>
                        New Production Plan
                    </v-btn>
                    <!--Scanner Button-->
                    <v-btn
                        elevation="2"
                        
                        dark
                        class="ml-2"
                        @click="$router.push('/production-plan-scanner')"
                    >
                        <v-icon left>mdi-qrcode-scan</v-icon>
                        Scanner
                    </v-btn>
                </v-card-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-sheet elevation="4">
                    <v-data-table
                        :headers="productionPlanHeaders"
                        :items="filteredPlans"
                        :items-per-page="500"
                        dense
                        show-expand
                        single-expand
                        item-key="plan_id"
                        group-by="plan_date"
                    >
                        <!-- Filters -->
                        <template v-slot:top>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Search"
                                            single-line
                                            hide-details
                                            dense
                                            outlined
                                            clearable
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select
                                            v-model="statusFilter"
                                            :items="statusOptions"
                                            label="Status"
                                            clearable
                                            item-text="text"
                                            item-value="value"
                                            dense
                                            outlined
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <!--Line item expansion dropdown-->
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="px-0">
                                <v-sheet elevation="0" class="py-3">
                                    <v-container>
                                        <v-row>
                                            <v-col md="6" sm="6">
                                                <h4>Item name</h4>
                                            </v-col>
                                            
                                            <v-col md="3" sm="3">
                                                <h4>Total quantity</h4>
                                            </v-col>

                                            <v-col md="3" sm="3">
                                                <h4>Related orders</h4>
                                            </v-col>
                                        </v-row>
                                        <div
                                            v-for="(e, index) in item.plan_items"
                                            :key="e.index"
                                        >
                                            <v-row class="py-2">
                                                <v-col md="6" sm="6">
                                                    {{e.combined_name}}
                                                </v-col>
                                            
                                                <v-col md="3" sm="3">
                                                    {{e.total_qty}}
                                                </v-col>
                                                <v-col md="3" sm="3">
                                                    {{e.orders}}
                                                </v-col>
                                            </v-row>
                                        <v-divider
                                            v-if="index < item.plan_items.length -1"
                                            :key="index"
                                        ></v-divider>
                                        </div>
                                    </v-container>
                                </v-sheet>
                            </td>
                        </template>
                        <!-- Plan name -->
                        <!-- Order branch -->
                        <template v-slot:item.plan_name="{ item }">
                            {{ item.plan_name ? item.plan_name : "" }}
                        </template>
                        <!-- Status -->
                        <template v-slot:item.plan_status="{ item }">
                            <v-chip :color="getStatusColor(item.plan_status)" small>
                                {{ item.plan_status }}
                            </v-chip>
                        </template>
                        <!-- Action items -->
                        <template v-slot:item.actions="{ item }">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="prepareJobTraveller(item)">
                                        <v-list-item-title>Print Job Traveller <v-icon small right>mdi-printer</v-icon></v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="printLabels(item)">
                                        <v-list-item-title>Print Labels <v-icon small right>mdi-label</v-icon></v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="item.plan_status === 'Created'" @click="updatePlanStatus(item, 'Completed')">
                                        <v-list-item-title>Mark as "Complete" <v-icon small right>mdi-check</v-icon></v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="item.plan_status === 'Created'" @click="updatePlanStatus(item, 'Cancelled')">
                                        <v-list-item-title>Mark as "Cancelled" <v-icon small right>mdi-close</v-icon></v-list-item-title>
                                    </v-list-item>
                                    <!-- Add more actions here -->
                                </v-list>
                            </v-menu>
                        </template>
                        <!-- Group by plan date -->
                        <template v-slot:group.header="{ items }">
                            <th
                            :colspan="productionPlanHeaders.length"
                            class="indigo lighten-5"
                            style="height: 36px"
                            >
                            {{
                                new Date(items[0].plan_date).toLocaleDateString("en-GB", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                })
                            }}
                            </th>
                        </template>
                    </v-data-table>
                </v-sheet>
            </v-col>
        </v-row>
        <!-- Traveller Settings Dialog -->
        <v-dialog v-model="travellerDialog" max-width="800px">
            <v-card v-if="currentPlan">
                <v-card-title class="text-h5">{{ currentPlan.plan_number }}</v-card-title>
                <v-data-table
                    v-model="selectedPageOptions"
                    :headers="pageOptionsHeaders"
                    :items="pageOptions"
                    show-select
                    item-key="label"
                    item
                    selectable-key="lable"
                    :items-per-page="500"
                    hide-default-footer
                >
                    
                </v-data-table>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="travellerDialog = false">Close</v-btn>
                    <!--Generate Traveller-->
                    <v-btn color="primary" @click="generateJobTraveller">Print Traveller</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import db from '../../../components/firebaseInit';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import JsBarcode from 'jsbarcode';
export default {
    name: "NewProductionPlan",
    mixins: [mixin_UserRoles],
    data() {
        return {
            production_plans: [],
            productionPlanHeaders: [
                { text: 'Plan #', value: 'plan_number' },
                { text: 'Name', value: 'plan_name' },
                { text: 'Date', value: 'plan_date' },
                { text: 'Status', value: 'plan_status' },
                { text: 'Actions', value: 'actions', align: 'right' },
            ],
            search: '',
            statusFilter: "Created",
            statusOptions: [
                { text: 'Created', value: 'Created' },
                { text: 'Completed', value: 'Completed' },
                { text: 'Cancelled', value: 'Cancelled' }
            ],
            travellerDialog: false,
            currentPlan: null,
            pageOptions: [
                { label: 'Cover Page', selected: true, method: 'addCoverPage' },
                { label: 'Manager Copy', selected: true, method: 'addManagerCopy' },
                { label: 'Traveller Copy', selected: true, method: 'addTravellerCopy' },
                { label: 'Job Card Index', selected: true, method: 'addJobCardIndex' },
                { label: 'Cushion List', selected: true, method: 'addCushionList' },
                { label: 'Frame List', selected: true, method: 'addFrameList' },
                { label: 'Fabric Picking List', selected: true, method: 'addFabricPickingList' },
                { label: 'Job Cards', selected: true, method: 'addJobCards' },
                { label: 'Item Labels', selected: true, method: 'addItemLabels' }
            ],
            pageOptionsHeaders: [
                { text: 'Page', value: 'label' },
            ],
            selectedPageOptions: [
                { label: 'Cover Page', selected: true, method: 'addCoverPage' },
                { label: 'Manager Copy', selected: true, method: 'addManagerCopy' },
                { label: 'Traveller Copy', selected: true, method: 'addTravellerCopy' },
                { label: 'Job Card Index', selected: true, method: 'addJobCardIndex' },
                { label: 'Cushion List', selected: true, method: 'addCushionList' },
                { label: 'Frame List', selected: true, method: 'addFrameList' },
                { label: 'Fabric Picking List', selected: true, method: 'addFabricPickingList' },
                { label: 'Job Cards', selected: true, method: 'addJobCards' },
                { label: 'Item Labels', selected: true, method: 'addItemLabels' }
            ],

        }
    },
    computed: {
        filteredPlans() {
            return this.production_plans.filter((plan) => {
                const statusMatch = this.statusFilter ? plan.plan_status === this.statusFilter : true;
                const searchMatch = plan.plan_name?.toLowerCase().includes(this.search.toLowerCase()) ||
                                    plan.plan_number.toLowerCase().includes(this.search.toLowerCase());
                return statusMatch && searchMatch;
            });
        }
    },
    methods: {
        getProductionPlans() {
            let query = db.collection('production_plans');

            
            query.orderBy('plan_number').onSnapshot((snapshot) => {
                this.production_plans = snapshot.docs.map((doc) => ({ ...doc.data() }));
            });
        },
        //
        getStatusColor(status) {
            switch (status) {
                case 'Created':
                    return 'green lighten-2';
                case 'Completed':
                    return 'grey lighten-2';
                case 'Cancelled':
                    return 'red lighten-2';
                default:
                    return 'grey';
            }
        },
        //
        async updatePlanStatus({ plan_id }, plan_status) {
            if(!confirm("Are you sure you want to update the status of this plan?")) return;
            try {
                await db.collection("production_plans")
                    .doc(plan_id)
                    .update({ plan_status });
            } catch (error) {
                console.error(error);
            }
        },
        //
        prepareJobTraveller(plan) {
            this.currentPlan = plan; 
            this.travellerDialog = true;  // Open the modal
        },
        //
        addCoverPage(doc, plan) {
            doc.setFontSize(20);
            doc.setFont(undefined, 'bold');
            doc.text('MANUFACTURING JOB TRAVELLER', 148, 70, null, null, 'center');

            doc.setFontSize(30);
            doc.setFont(undefined, 'normal');
            doc.text(`${plan.plan_number}`, 148, 90, null, null, 'center');
            doc.setFontSize(16);
            doc.text(`Plan Name: ${plan.plan_name}`, 148, 110, null, null, 'center');
            doc.text(`Date: ${plan.plan_date}`, 148, 120, null, null, 'center');

            // Barcode to scan to mark plan as 'Completed'
            const barcodeCanvas = document.createElement('canvas');
            JsBarcode(barcodeCanvas, plan.plan_id, {
                format: 'CODE128',
                displayValue: false,
                fontSize: 18,
                lineHeight: 2,
                width: 2,
                height: 60,
                margin: 10,
            });
            const barcodeDataUrl = barcodeCanvas.toDataURL('image/png');
            const barcodeWidth = 160; // Increase the width of the barcode image
            const barcodeHeight = 40; // Increase the height of the barcode image
            const xPosition = 148 - (barcodeWidth / 2); // Center align the barcode
            const yPosition = 125; // Position the barcode below the plan details
            doc.addImage(barcodeDataUrl, 'PNG', xPosition, yPosition, barcodeWidth, barcodeHeight);
            doc.setFontSize(10);
            doc.text("Scan barcode to mark as 'Completed'", 148, yPosition + barcodeHeight + 10, null, null, 'center');
        },
        //
        addJobCards(doc, plan) {
            const workstations = ["Fabric Cutting", "Sewing", "Upholstery", "Assembly", "Packaging"];
            let jobCardCount = 0; // Initialize job card count
            const totalJobCards = plan.plan_items.reduce((acc, item) => acc + item.line_item_details.length, 0); // Calculate total number of job cards

            plan.plan_items.forEach((item, index, array) => {
                item.line_item_details.forEach((detail) => {
                    jobCardCount++; // Increment job card count
                    // JOB CARD Header
                    doc.setFontSize(20);
                    doc.setFont(undefined, 'bold');
                    doc.text(`JOB CARD ${plan.plan_number}-${String(jobCardCount).padStart(2, '0')}`, 148, 30, null, null, 'center');
                    doc.setFontSize(16);
                    doc.text(`Item ${jobCardCount} of ${totalJobCards}`, 148, 40, null, null, 'center');

                    // Job Details Table
                    const jobDetailsTableData = [
                        [plan.plan_number, item.combined_name.split(' - ')[0], item.combined_name.split(' - ')[1], detail.item_qty, detail.order_number, detail.comments || ""]
                    ];

                    doc.autoTable({
                        head: [["Plan #", "Product Name", "Variant", "Quantity", "Order Number", "Comments"]],
                        body: jobDetailsTableData,
                        startY: 60,
                        theme: 'grid',
                        headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
                        styles: { fontSize: 12, cellPadding: 2 },
                        columnStyles: { 4: { cellWidth: 60 } } // Adjust width of comments column
                    });

                    // Workstation Tracking Table
                    const trackingTableData = workstations.map(station => [station, "", "", ""]); 
                    doc.autoTable({
                        head: [["Workstation", "Date", "Name", "Signature"]],
                        body: trackingTableData,
                        startY: doc.lastAutoTable.finalY + 10, // Position below the job details table
                        theme: 'grid',
                        headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
                        styles: { fontSize: 10, cellPadding: 2 }
                    });
                    const isLastItem = index === array.length - 1;
                    if (!isLastItem) {
                        doc.addPage();
                    }
                });
            });
        },
        //
        addItemLabels(doc, plan) {
            let firstLabelAdded = false;  // Flag to track if the first label has been added

            plan.plan_items.forEach((item) => {
                item.line_item_details.forEach((detail) => {
                    for (let i = 0; i < detail.item_qty; i++) {
                        if (firstLabelAdded) {
                            doc.addPage();  // Add a new page for each subsequent label
                        } else {
                            firstLabelAdded = true;  // Set the flag after the first label
                        }

                        // Set the font size and type for the header
                        doc.setFontSize(12);
                        doc.setFont(undefined, 'bold');
                        doc.text("Item Label", 148, 70, null, null, 'center');

                        // Item Label Header
                        doc.setFontSize(30);
                        doc.setFont(undefined, 'bold');
                        doc.text(`${item.combined_name.split(' - ')[0]}`, 148, 90, null, null, 'center');

                        // Item Details
                        doc.setFontSize(22);
                        doc.setFont(undefined, 'normal');
                        doc.text(`Variant: ${item.combined_name.split(' - ')[1]}`, 148, 110, null, null, 'center');
                        doc.text(`Order: ${detail.order_number}`, 148, 125, null, null, 'center');
                        doc.text(`Quantity: ${i + 1} of ${detail.item_qty}`, 148, 140, null, null, 'center'); // Add quantity info
                        doc.text(`${plan.plan_number}`, 148, 155, null, null, 'center');
                    }
                });
            });
        },
        //
        addIndexPage(doc, plan, labelText) {
            const labelTableColumn = ["Job Card #", "Product Name", "Variant", "Quantity", "Order Number", "Comments", "Done"];
            const labelTableRows = [];
            let jobCardCount = 0

            plan.plan_items.forEach((item) => {
                item.line_item_details.forEach((detail) => {
                    jobCardCount++;
                    const productName = item.combined_name.split(' - ')[0];
                    const variant = item.combined_name.split(' - ')[1];
                    const quantity = detail.item_qty;
                    const orderNumber = detail.order_number;
                    const comments = detail.comments;

                    const labelData = [
                        `${plan.plan_number}-${String(jobCardCount).padStart(2, '0')}`,
                        productName,
                        variant,
                        quantity,
                        orderNumber,
                        comments
                    ];
                    labelTableRows.push(labelData);
                });
            });

            doc.setFontSize(20);
            doc.setFont(undefined, 'bold');
            doc.text(`JOB CARD LIST FOR ${plan.plan_number} ${labelText}`, 148, 30, null, null, 'center');
            
            doc.setFontSize(16);
            doc.autoTable({
                head: [labelTableColumn],
                body: labelTableRows,
                theme: 'grid',
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
                styles: { fontSize: 9, cellPadding: 2 },
                startY: 40
            });
        },
        //
        addManagerCopy(doc, plan) {
            this.addIndexPage(doc, plan, "(MANAGER COPY)")
        },
        // 
        addTravellerCopy(doc, plan) {
            this.addIndexPage(doc, plan, "(TRAVELLER COPY)");
        },
        //
        addJobCardIndex(doc, plan) {
            this.addIndexPage(doc, plan, "INDEX");
        },
        //
        addCushionList(doc, plan) {
            const labelTableColumn = ["Job Card #", "Product Name", "Variant", "Quantity", "Order Number", "Comments", "Done"];
            const labelTableRows = [];
            let jobCardCount = 0;

            plan.plan_items.forEach((item) => {
                item.line_item_details.forEach((detail) => {
                    jobCardCount++;
                    const productName = item.combined_name.split(' - ')[0];
                    const variant = item.combined_name.split(' - ')[1];
                    const quantity = detail.item_qty;
                    const orderNumber = detail.order_number;
                    const comments = detail.comments;

                    const labelData = [
                        `${plan.plan_number}-${String(jobCardCount).padStart(2, '0')}`,
                        productName,
                        variant,
                        quantity,
                        orderNumber,
                        comments
                    ];
                    labelTableRows.push(labelData);
                });
            });

            doc.setFontSize(20);
            doc.setFont(undefined, 'bold');
            doc.text(`CUSHION LIST FOR ${plan.plan_number}`, 148, 30, null, null, 'center');
            
            doc.setFontSize(16);
            doc.autoTable({
                head: [labelTableColumn],
                body: labelTableRows,
                theme: 'grid',
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
                styles: { fontSize: 9, cellPadding: 2 },
                startY: 40
            });
        },
        //
        addFrameList(doc, plan) {
            const labelTableColumn = ["Job Card #", "Product Name", "Quantity", "Order Number", "Comments", "Done"];
            const labelTableRows = [];
            let jobCardCount = 0;

            plan.plan_items.forEach((item) => {
                item.line_item_details.forEach((detail) => {
                    jobCardCount++;
                    const productName = item.combined_name.split(' - ')[0];
                    const quantity = detail.item_qty;
                    const orderNumber = detail.order_number;
                    const comments = detail.comments;

                    const labelData = [
                        `${plan.plan_number}-${String(jobCardCount).padStart(2, '0')}`,
                        productName,
                        quantity,
                        orderNumber,
                        comments
                    ];
                    labelTableRows.push(labelData);
                });
            });

            doc.setFontSize(20);
            doc.setFont(undefined, 'bold');
            doc.text(`FRAME LIST FOR ${plan.plan_number}`, 148, 30, null, null, 'center');
            
            doc.setFontSize(16);
            doc.autoTable({
                head: [labelTableColumn],
                body: labelTableRows,
                theme: 'grid',
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
                styles: { fontSize: 9, cellPadding: 2 },
                startY: 40
            });
        },
        //
        addFabricPickingList(doc, plan) {
            const labelTableColumn = ["#", "FABRIC NAME"];
            const labelTableRows = [];

            plan.plan_items.forEach((item) => {
                const variant = item.combined_name.split(' - ')[1];
                if (variant) {  // Ensure the variant exists before adding
                    labelTableRows.push([variant]);
                }
            });

            // Using a Set to ensure unique fabric names only
            const uniqueFabricNames = new Set(labelTableRows.map(row => row[0]));

            console.log(uniqueFabricNames);

            doc.setFontSize(20);
            doc.setFont(undefined, 'bold');
            doc.text(`FABRIC PICKING LIST ${plan.plan_number}`, 148, 30, null, null, 'center');
            
            doc.setFontSize(16);
            // Convert the set to an array and map over it to create row data with numbers
            const bodyData = Array.from(uniqueFabricNames).map((name, index) => [index + 1, name]);

            doc.autoTable({
                head: [labelTableColumn],
                body: bodyData,
                theme: 'grid',
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
                styles: { fontSize: 12, cellPadding: 3 },
                startY: 40
            });
        },
        //
        generateJobTraveller() {
            const doc = new jsPDF('landscape');

            this.selectedPageOptions.forEach((page, index, array) => {
                this[page.method](doc, this.currentPlan)

                const isLastItem = index === array.length - 1;

                if (!isLastItem) {
                    doc.addPage();
                }
            });

            // Add page numbers and finalize PDF
            const pageCount = doc.getNumberOfPages();
            for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text(`Page ${i} of ${pageCount}`, 270, 10);
            }

            doc.save(`${this.currentPlan.plan_number}_Job_Traveller.pdf`);
            this.dialog = false;  // Close the modal after generating the PDF
        },

        //
        printLabels(plan) {
            const doc = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4'
            });

            const labelsPerRow = 2;
            const labelsPerColumn = 4;
            const labelWidth = 105;
            const labelHeight = 74;

            let labelCount = 0; // This will keep track of the total number of labels generated.

            plan.plan_items.forEach((item) => {
                item.line_item_details.forEach((detail) => {
                    for (let qty = 0; qty < detail.item_qty; qty++) {
                        // Calculate position for each label
                        let x = (labelCount % labelsPerRow) * labelWidth;
                        let y = Math.floor(labelCount / labelsPerRow) % labelsPerColumn * labelHeight;
                        
                        // Check if a new page is needed
                        if (labelCount > 0 && labelCount % (labelsPerRow * labelsPerColumn) === 0) {
                            doc.addPage();
                            y = 0;  // Reset y-coordinate for new page
                        }

                        // Add label content with center alignment
                        const centerX = x + labelWidth / 2; // Calculate the center x-coordinate for text
                        const startY = y + 20; // Starting y position for text

                        doc.setDrawColor(0);
                        doc.setFillColor(255, 255, 255);
                        doc.rect(x, y, labelWidth, labelHeight, 'F');
                        doc.setFontSize(14);
                        doc.setFont(undefined, 'bold');
                        doc.text(`${detail.order_number}`, centerX, startY, null, null, 'center');
                        doc.setFontSize(11);
                        doc.setFont(undefined, 'normal');
                        doc.text(`Plan: ${plan.plan_number}`, centerX, startY + 10, null, null, 'center');
                        doc.text(`${item.combined_name.split("-")[0]}`, centerX, startY + 20, null, null, 'center');

                        const hasVariants = detail.variant_name;

                        if (hasVariants) {
                            doc.text(`Variant: ${item.combined_name.split("-")[1]}`, centerX, startY + 30, null, null, 'center');
                        }

                        doc.text(`Qty: ${qty + 1} of ${detail.item_qty}`, centerX, startY + (hasVariants ? 37.5 : 30), null, null, 'center');

                        labelCount++; // Increment label count after each label
                    }
                });
            });

            // Save the PDF
            doc.save('labels.pdf');
        },
    },
    created() {
        this.getProductionPlans();
    },
}
</script>
