<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card-title>
                    <h1>New Production Plan</h1>
                    <!--Button-->
                    <v-spacer />
                    <v-btn :disabled="selectedItems.length === 0" @click="printProductionPlanPDF">Print PDF <v-icon small right>mdi-download</v-icon></v-btn>
                </v-card-title>
                <v-card class="mt-5">
                    <v-form @submit.prevent="submitProductionPlan" class="pa-3" ref="form" v-model="valid">
                        <v-container>
                            <v-row dense>
                                <!-- Plan Number -->
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="plan_number"
                                        readonly
                                        label="Plan #"
                                        outlined
                                        clearable
                                        dense
                                        :rules="[rules.required]"
                                        @input="$refs.form.validate()"
                                    ></v-text-field>
                                </v-col>
                                <!-- Plan Status -->
                                <v-col cols="12" sm="6" md="6">
                                    <v-select
                                        v-model="plan_status"
                                        label="Status"
                                        :items="statusList"
                                        outlined
                                        clearable
                                        dense
                                        :rules="[rules.required]"
                                        @input="$refs.form.validate()"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <!-- Plan Name -->
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="plan_name"
                                        label="Plan Name"
                                        outlined
                                        clearable
                                        dense
                                    ></v-text-field>
                                </v-col>
                                <!-- Plan Date -->
                                <v-col cols="12" sm="6" md="6">
                                    <v-menu
                                        v-model="menuPlanDate"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="plan_date"
                                                label="Plan Date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                outlined
                                                dense
                                                :rules="[rules.required]"
                                                @input="$refs.form.validate()"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="plan_date"
                                            no-title
                                            color="primary"
                                            @input="menuPlanDate = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-form>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            to='/production-planning'
                            color="#ce2458"
                            text
                            x-large
                        >
                            Cancel
                            <v-icon right>mdi-close</v-icon>
                        </v-btn>
                        <v-btn
                            @click="submitProductionPlan"
                            color="#33cc99"
                            text
                            x-large
                            :disabled="valid === false || !selectedItemsValid"
                            :loading="loading"
                        >
                            Submit
                            <v-icon right>mdi-check</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
        </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-sheet elevation="4">
                    <v-data-table
                        v-model="selectedItems"
                        :headers="lineItemHeaders"
                        :items="filteredLineItems"
                        :items-per-page="500"
                        item-key="item_key"
                        show-select
                        dense
                        @input="$refs.form.validate()"
                    >
                        <template v-slot:top>
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <!-- Individual Selection Toggle -->
                                        <v-switch
                                            v-model="individualSelection"
                                            label="Individual Selection"
                                            class="mb-4"
                                        ></v-switch>
                                        <!-- Your existing data table code -->
                                    </v-col>
                                    <!-- Search -->
                                    <v-col>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Search"
                                            single-line
                                            hide-details
                                            outlined
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                    <!-- Branch filter -->
                                    <v-col>
                                        <v-select
                                            v-model="selectedBranch"
                                            :items="branchList"
                                            label="Select Branch"
                                            item-text="branch_name"
                                            item-value="branch_id"
                                            outlined
                                            dense
                                            clearable
                                        ></v-select>
                                    </v-col>
                                    <!-- Date Range filter-->
                                    <v-col>
                                        <v-menu
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="formattedDateRange"
                                                    label="Date Range"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="dateRange"
                                                range
                                                no-title
                                                color="primary"
                                                @input="menu = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </v-data-table>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import db from '../../../components/firebaseInit'
import firebase from "firebase"
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
export default {
    name: "NewProductionPlan",
    mixins: [mixin_UserRoles],
    data() {
        return {
            search: '',
            valid: false,
            loading: false,
            plan_number: null,
            plan_name: null,
            plan_status: 'Created',
            plan_date: null,
            menuPlanDate: false,
            selectedBranch: '',
            statusList: ['Created', 'Completed', 'Cancelled'],
            menu: false,
            dateRange: [null, null],
            sales_orders: [],
            selectedItems: [],
            selectedItemsValid: false,
            branchList: [],
            lineItemHeaders: [
                { text: 'Item', value: 'combined_name' },
                { text: 'Total Quantity', value: 'total_qty' },
                { text: 'Orders', value: 'orders' },
            ],
            rules: {
                required: value => !!value || 'Required.',
                planItems: value => (value && value.length > 0) || 'Plan items cannot be empty.',
            },
            individualSelection: true,
        }
    },
    computed: {
        lineItemList() {
            return this.sales_orders.flatMap(order => {
                const { order_id, order_number, line_items, order_branch, order_date } = order;
                return line_items.filter(item => !item.assigned_to_plan).map(item => ({
                    ...item,
                    order_id,
                    order_number,
                    order_branch,
                    order_date,
                    unique_id: {
                        product_id: item.product_id,
                        variant_set_name: item.variant ? item.variant.variant_set_name : null,
                        variant_name: item.variant ? item.variant.variant_name : null,
                        order_id,
                        order_branch: item.order_branch ?  order_branch.branch_id : null,
                    },
                    combined_name: item.variant ? `${item.product_name} - ${item.variant.variant_set_name} ${item.variant.variant_name}` : item.product_name
                }));
            });
        },
        //
        filteredLineItems() {
            const filteredItems = this.lineItemList.filter(item => {
                const branchMatch = !this.selectedBranch || (item.order_branch && item.order_branch.branch_id === this.selectedBranch);
                const searchMatch = item.combined_name.toLowerCase().includes(this.search.toLowerCase());
                const dateMatch = (!this.dateRange[0] || item.order_date >= this.dateRange[0]) && (!this.dateRange[1] || item.order_date <= this.dateRange[1]);
                return branchMatch && searchMatch && dateMatch;
            });

            if (this.individualSelection) {
                // Return individual items
                return filteredItems.map((item, index) => ({
                    combined_name: item.combined_name,
                    total_qty: item.item_qty,
                    orders: item.order_number,
                    item_key: `${item.order_number}-${item.combined_name}-${index}`,
                    line_item_details: [{
                        order_id: item.order_id,
                        order_number: item.order_number,
                        order_branch: item.order_branch ? item.order_branch.branch_id : null,
                        product_id: item.product_id,
                        variant_set_name: item.variant ? item.variant.variant_set_name : null,
                        variant_name: item.variant ? item.variant.variant_name : null,
                        item_qty: item.item_qty,
                        comments: item.line_item_comment ? item.line_item_comment : null,
                    }],
                }));
            } else {
                // Group items as before
                const grouped = {};
                filteredItems.forEach(item => {
                    const key = item.combined_name;
                    if (!grouped[key]) {
                        grouped[key] = {
                            combined_name: key,
                            total_qty: 0,
                            order_numbers: new Set(),
                            item_key: key,
                            line_item_details: []
                        };
                    }
                    grouped[key].total_qty += item.item_qty;
                    grouped[key].order_numbers.add(item.order_number);
                    grouped[key].line_item_details.push({
                        order_id: item.order_id,
                        order_number: item.order_number,
                        order_branch: item.order_branch ? item.order_branch.branch_id : null,
                        product_id: item.product_id,
                        variant_set_name: item.variant ? item.variant.variant_set_name : null,
                        variant_name: item.variant ? item.variant.variant_name : null,
                        item_qty: item.item_qty,
                        comments: item.line_item_comment ? item.line_item_comment : null,
                    });
                });

                return Object.values(grouped).map(item => ({
                    ...item,
                    orders: Array.from(item.order_numbers).join(', '),
                })).sort((a, b) => a.combined_name.localeCompare(b.combined_name));
            }
        },

        //
        formattedDateRange() {
            if (this.dateRange[0] && this.dateRange[1]) {
                return `${this.dateRange[0]} - ${this.dateRange[1]}`;
            }
            return '';
        },
    },
    methods: {
        getSalesOrders() {
            let query = db.collection('sales_orders').where('order_status', '==', 'Confirmed');

            // Apply date range filter if both start and end dates are selected
            if (this.dateRange[0] && this.dateRange[1]) {
                query = query.where('order_date', '>=', this.dateRange[0])
                            .where('order_date', '<=', this.dateRange[1]);
            }

            query.orderBy('order_number').onSnapshot((snapshot) => {
                this.sales_orders = snapshot.docs.map((doc) => {
                    const { order_id, order_number, line_items, order_branch, order_date } = doc.data();
                    return { order_id, order_number, line_items, order_branch, order_date };
                });
            });
        },
        //
        getBranchList() {
            const docRef = db.collection('branches').orderBy('branch_name');

            docRef.onSnapshot((snapshot) => {
                this.branchList = snapshot.docs.map((doc) => doc.data());
            });
        },
        // Get plan number count
        getPlanNumber() {
            const docRef = db.collection('production_plan_counter').doc('lmA8AxqaF9ZZKoorTKrY');

            docRef.onSnapshot((snap) => {
                const { plan_count } = snap.data();
                const formattedCount = `${plan_count + 1}`.padStart(4, '0');
                this.plan_number = `PROD-${formattedCount}`;
            });
        },
        // Save production plan to database
        async submitProductionPlan() {
            if (!this.$refs.form.validate()) {
                // Form is not valid
                return;
            }

            this.loading = true;

            const batch = db.batch();

            const planRef = db.collection('production_plans').doc();
            const planData = {
                plan_id: planRef.id,
                plan_number: this.plan_number,
                plan_name: this.plan_name,
                plan_status: this.plan_status,
                plan_date: this.plan_date,
                plan_items: this.selectedItems.map(item => ({
                    combined_name: item.combined_name,
                    total_qty: item.total_qty,
                    orders: item.orders,
                    line_item_details: item.line_item_details
                }))
            };

            console.log("Plan items before saving:", planData.plan_items);

            batch.set(planRef, planData);

            const counterRef = db.collection('production_plan_counter').doc('lmA8AxqaF9ZZKoorTKrY');
            const increment = firebase.firestore.FieldValue.increment(1);
            batch.update(counterRef, { plan_count: increment });

            try {
                await batch.commit();
                this.$router.push('/production-planning');
            } catch (error) {
                console.error("Error saving production plan: ", error);
                this.$emit('error', 'Error saving production plan');
            } finally {
                this.loading = false;
            }
        },
        printProductionPlanPDF() {
            if (this.selectedItems.length === 0 ) return;
            const doc = new jsPDF();
            const itemTableColumn = ["Item Name", "Qty", "Orders"];
            const itemTableRows = [];

            this.selectedItems.forEach(item => {
                const itemData = [
                    item.combined_name,
                    item.total_qty,
                    item.orders
                ];
                itemTableRows.push(itemData);
            });

            doc.setFontSize(16);
            doc.setFont(undefined, 'bold');
            doc.text("PRODUCTION PLAN", 105, 20, null, null, 'center');
            // Check if plan_name is not empty and add it as a subheading
            if (this.plan_name.trim() !== '') {
                doc.setFontSize(14);
                doc.text(this.plan_name, 105, 28, null, null, 'center');
            }

            // Add formatted date range if available
            if (this.formattedDateRange.trim() !== '') {
                doc.setFontSize(12);
                doc.text(this.formattedDateRange, 105, this.plan_name.trim() !== '' ? 36 : 32, null, null, 'center');
            }

            doc.autoTable({
                head: [itemTableColumn],
                body: itemTableRows,
                startY: this.plan_name.trim() !== '' || this.formattedDateRange.trim() !== '' ? 42 : 34, // Adjust startY based on plan_name and dateRange
                theme: 'grid',
                headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
                styles: { fontSize: 9, cellPadding: 2 }
            });

            doc.save("ProductionPlan.pdf");
        }
    },
    created() {
        this.getSalesOrders();
        this.getBranchList();
        this.getPlanNumber();
    },
    watch: {
        selectedItems(newVal) {
            this.selectedItemsValid = newVal.length > 0;
            this.$nextTick(() => {
                this.$refs.form.validate(); // Trigger form validation to update the valid state
            });
        },
        individualSelection() {
            this.selectedItems = []; // Clear selected items when toggling individual selection
        },
    },
}
</script>

<style>
/* Add any additional styling you need here */
</style>
