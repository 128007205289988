<template>
    <v-container>
      <v-alert type="info" class="mb-4">
        Scan a barcode to mark the production plan as "Completed".
      </v-alert>
      <v-alert v-if="lastScanned" type="success" class="mt-4">
        <div>Last Scanned: {{ lastScanned.code }}</div>
        <div>Time: {{ lastScanned.time | formatDate }}</div>
        </v-alert>
    
      <div id="barcode-scanner" class="d-flex justify-center pa-3">
        <div id="scanner-container"></div>
      </div>
    </v-container>
  </template>
  
  <script>
  import db from '../../../components/firebaseInit';
  import Quagga from 'quagga';
  
  export default {
    name: 'ProductionPlanScanner',
    data() {
      return {
        lastScanned: null,
        scanDelay: 5000 // 5 seconds delay between scans
      };
    },
    mounted() {
      Quagga.init({
        inputStream: {
          type: 'LiveStream',
          target: document.querySelector('#scanner-container'),
          constraints: {
            width: window.innerWidth < 640 ? window.innerWidth : 640,
            height: window.innerWidth < 640 ? (window.innerWidth * 3) / 4 : 480, // Maintain a 4:3 aspect ratio
            facingMode: 'environment'
          }
        },
        locator: {
            patchSize: 'medium',
            halfSample: true,
        },
        decoder: {
          readers: ['code_128_reader']
        }
      }, (err) => {
        if (err) {
          console.error(err);
          return;
        }
        Quagga.start();
      });
  
      Quagga.onDetected(this.onBarcodeDetected);
    },
    beforeDestroy() {
      Quagga.stop();
    },
    methods: {
      onBarcodeDetected(result) {
        const barcode = result.codeResult.code;
        const currentTime = new Date().getTime();
  
        if (this.lastScanned && this.lastScanned.code === barcode &&
            (currentTime - this.lastScanned.time) < this.scanDelay) {
          // Ignore duplicate scan
          return;
        }
  
        this.lastScanned = { code: barcode, time: currentTime };
        this.updatePlanStatus(barcode);
      },
      
      async updatePlanStatus(barcode) {
            try {
                const docRef = db.collection("production_plans").doc(barcode);
                console.log("Barcode: ", barcode)
                const docSnapshot = await docRef.get();

                if (docSnapshot.exists) {
                const plan = docSnapshot.data();
                if (plan.plan_status === "Completed") {
                    console.log("Plan is already completed.");
                    return; 
                }

                await docRef.update({ plan_status: "Completed" });
                console.log("Plan marked as completed.");
                } else {
                console.log("Plan not found.");
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    filters: {
      formatDate(value) {
        if (!value) return '';
        const date = new Date(value);
        return date.toLocaleTimeString();
      }
    }
  };
  </script>
  
  <style scoped>
  #barcode-scanner {
    width: 100%;
  }
  
  #scanner-container {
    width: 100%;
    max-width: 640px;
    height: auto; /* Adjust height based on width to maintain aspect ratio */
    margin: auto;
    position: relative;
  }
  </style>
  